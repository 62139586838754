import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
    // API's FOR PATIENT MAPPING
    async getMappedEcpUsersForHospital (id) {
        const response = await _axios.get('ecp/getmappedecpresidentsforhospital', { headers: { hospitalId: id } });
        if (response.data.resFlag) {
            const userJson = response.data.data;
            return userJson;
        } else {
            throw new UsersError('Invalid id');
        }
    },
    async deleteMappedUser (id) {
        const response = await _axios.delete(`ecp/${id}`);
        if (response.data.resFlag) {
            const userJson = response.data.data;
            return userJson;
        } else {
            throw new UsersError('Invalid id');
        }
    },
    async mapUser (body) {
        const response = await _axios.post('ecp/addmappedecpuser', body);
        if (response.data.resFlag) {
            const userJson = response.data;
            return userJson;
        } else {
            throw new UsersError('Password could not be changed!');
        }
    },

    // API's MEDICAL STAFF MAPPING

    async getMappedEcpMedicalStaffForHospital (id) {
        const response = await _axios.get('ecp/getmappedecpmedicalstaffforhospital', { headers: { hospitalId: id } });
        if (response.data.resFlag) {
            const userJson = response.data.data;
            return userJson;
        } else {
            throw new UsersError('Invalid id');
        }
    },
};
